<!-- HTML -->
<template>
  <section class="sobre">
    <div class="quadros">
      <div class="titulo">
        <h2>SAIBA <span>O QUE TE ESPERA</span> NA WIZOOM PLAY</h2>
      </div>
      <div class="quadro direita">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-selo.svg">
        <p>Seja Bem-Vindo à <b>maior Escola para a Formação e Desenvolvimento de Profissionais da Estética Automotiva no Brasil.</b> Já são mais de 14 mil alunos treinados.</p>
        <span></span>
      </div>
      <div class="quadro esquerda">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-carro.svg">
        <p>Aprenda desde realizar serviços como <b>polimento, higienização, lavagens técnicas, vitrificação, aplicação de insulfilm/fumê, estética em motocicletas, lavagem de motores</b> e diversos outros cuidados automotivos.</p>
        <span></span>
      </div>
      <div class="quadro direita">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-dinheiro.svg">
        <p>Até <b>treinamentos de vendas, gestão, marketing, contratações,</b> expansão e aumento dos seus serviços.</p>
        <span></span>
      </div>
      <div class="quadro esquerda">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-saco.svg">
        <p>Você será levado em uma jornada por um ambiente online, <b>construído e pensado para você alcançar resultados,</b> facilitar seu aprendizado e motivar seu crescimento.</p>
        <span></span>
      </div>
      <div class="quadro direita">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-escudo.svg">
        <p>É hora de <b>construir um negócio lucrativo</b> no mercado da Estética Automotiva.</p>
      </div>
      <div class="botao">
        <button type="button" @click="rolarPara('section#cursos')"><Icones nome="check"/>Quero construir um negócio lucrativo</button>
      </div>
    </div>
    <!-- Blush -->
    <img class="blush um" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
    <img class="blush dois" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
  </section>
</template>

<!-- JS -->
<script>
import Icones from '../../../global/icones/Icones.vue'
export default {
  components: {
    Icones
  },
  methods: {
    rolarPara(id) {
      const sectionFotos = document.querySelector(id)
      window.scrollTo({
        top: sectionFotos.offsetTop - 50,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<!-- CSS -->
<style scoped>
  section.sobre {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px;
    position: relative;
    overflow: hidden;
    background-color: var(--cor-escuro-0);
    background: linear-gradient(to bottom, var(--cor-escuro-1), var(--cor-escuro-0));
  }
  /* Quadros */
  .quadros {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    z-index: 2;
  }
  .quadros .titulo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 0 20px 0;
  }
  .quadros .titulo h2 {
    font-family: var(--bold);
    font-size: clamp(1.6rem, 3vw, 2rem);
    color: var(--cor-branco);
    line-height: 1.2;
    width: 100%;
  }
  .quadros .titulo h2 span {
    color: var(--cor-wizoomplay);
  }
  .quadros button {
    display: flex;
    align-items: center;
    font-family: var(--bold);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    background-color: transparent;
    border: 2px solid var(--cor-wizoomplay);
    padding: 15px;
    border-radius: 10px;
    transition: all 0.3s;
    margin: 15px 0 0 0;
  }
  .quadros button svg {
    width: 15px;
    min-width: 15px;
    fill: var(--cor-branco);
    margin: 0 10px 0 0;
  }
  .quadros button:hover {
    text-shadow: 0 0 10px var(--cor-branco);
    box-shadow: 0 0 20px 0 var(--cor-wizoomplay);
  }
  .quadros .botao {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px 0 0 0;
  }
  /* Quadro */
  .quadro {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    margin: 30px 0 0 0;
  }
  .quadro.esquerda {
    margin-right: auto;
    background-color: var(--cor-escuro-2);
    transform: rotate(-3deg);
  }
  .quadro.direita {
    margin-left: auto;
    background-color: var(--cor-escuro-2);
    transform: rotate(3deg);
  }
  .quadro img {
    width: 100%;
    max-width: 150px;
    margin: 0 15px 0 0;
    z-index: 4;
  }
  .quadro span {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
  }
  .quadro.esquerda span {
    border-top: 5px solid var(--cor-escuro-2);
    border-right: 5px solid var(--cor-escuro-2);
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    right: -160px;
    bottom: -120px;
  }
  .quadro.direita span {
    border-top: 5px solid var(--cor-escuro-2);
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid var(--cor-escuro-2);
    left: -160px;
    bottom: -120px;
  }
  .quadro p {
    font-family: var(--regular);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    line-height: 1.5;
    z-index: 4;
  }
  .quadro p b {
    font-family: var(--bold);
    color: var(--cor-wizoomplay);
  }
  /* IMG blush */
  img.blush {
    width: 1200px;
    position: absolute;
    z-index: 1;
    animation: zoomBlush 10s linear infinite;
  }
  img.blush.um {
    opacity: 0.3;
    bottom: -400px;
    left: -200px;
  }
  img.blush.dois {
    opacity: 0.3;
    top: -400px;
    right: -200px;
  }
  @keyframes zoomBlush {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    section.sobre {
      padding: 40px 20px 50px 20px;
      background-color: var(--cor-escuro-0);
      background: linear-gradient(to bottom, var(--cor-escuro-1), var(--cor-escuro-0));
    }
    /* Quadro */
    .quadro {
      align-items: flex-start;
      width: 85%;
      padding: 15px;
      margin: 20px 0 0 0;
    }
    .quadros .titulo {
      padding: 0 0 10px 0;
    }
    .quadro.esquerda {
      transform: rotate(-2deg);
    }
    .quadro.direita {
      transform: rotate(2deg);
    }
    .quadro img {
      width: 100%;
      max-width: 70px;
      margin: 0 10px 0 0;
    }
    .quadro span {
      width: 150px;
      height: 150px;
      min-width: 150px;
      min-height: 150px;
    }
    .quadro.esquerda span {
      right: -50px;
      bottom: -80px;
    }
    .quadro.direita span {
      left: -50px;
      bottom: -80px;
    }
    .quadro p {
      font-family: var(--regular);
      font-size: clamp(0.9rem, 3vw, 1.1rem);
      color: var(--cor-branco);
      line-height: 1.5;
    }
    .quadro p b {
      font-family: var(--bold);
      color: var(--cor-wizoomplay);
    }
    /* IMG blush */
    img.blush {
      width: 800px;
    }
  }
</style>
