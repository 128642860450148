export default {
    state: {
        mostrarModalConteudo: false
    },
    mutations: {
        LP_POLIDOR_DE_ELITE_MOSTRAR_CONTEUDO(state, payload) {
            state.mostrarModalConteudo = payload
        }
    },
    namespaced: true
}