module.exports = 
[
    {
      id: 1,
      nome: "polidor",
      foto: "https://arquivos.wizoomplay.com/images/personagens/polidor-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/polidor.png",

      ativo: false
    },
    {
      id: 2,
      nome: "motoqueiro",
      foto: "https://arquivos.wizoomplay.com/images/personagens/motoqueiro-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/motoqueiro.png",
      ativo: false
    },
    {
      id: 3,
      nome: "alien_mib",
      foto: "https://arquivos.wizoomplay.com/images/personagens/alien-mib-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/alien-mib.png",
      ativo: false
    },
    {
      id: 4,
      nome: "bebado",
      foto: "https://arquivos.wizoomplay.com/images/personagens/bebado-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/bebado.png",
      ativo: false
    },
    {
      id: 5,
      nome: "capa_roxa",
      foto: "https://arquivos.wizoomplay.com/images/personagens/capa-roxa-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/capa-roxa.png",
      ativo: false
    },
    {
      id: 6,
      nome: "cacador_da_terra",
      foto: "https://arquivos.wizoomplay.com/images/personagens/cacador-da-terra-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/cacador-da-terra.png",
      ativo: false
    },
    {
      id: 7,
      nome: "dandada",
      foto: "https://arquivos.wizoomplay.com/images/personagens/dandada-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/dandada.png",
      ativo: false
    },
    {
      id: 8,
      nome: "dino",
      foto: "https://arquivos.wizoomplay.com/images/personagens/dino-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/dino.png",
      ativo: false
    },
    {
      id: 9,
      nome: "esquentadinho",
      foto: "https://arquivos.wizoomplay.com/images/personagens/esquentadinho-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/esquentadinho.png",
      ativo: false
    },
    {
      id: 10,
      nome: "exterminador",
      foto: "https://arquivos.wizoomplay.com/images/personagens/exterminador-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/exterminador.png",
      ativo: false
    },
    {
      id: 11,
      nome: "faisca",
      foto: "https://arquivos.wizoomplay.com/images/personagens/faisca-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/faisca.png",
      ativo: false
    },
    {
      id: 12,
      nome: "farao",
      foto: "https://arquivos.wizoomplay.com/images/personagens/farao-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/farao.png",
      ativo: false
    },
    {
      id: 13,
      nome: "garcom_bigodudo",
      foto: "https://arquivos.wizoomplay.com/images/personagens/garcom-bigodudo-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/garcom-bigodudo.png",
      ativo: false
    },
    {
      id: 14,
      nome: "gladiador",
      foto: "https://arquivos.wizoomplay.com/images/personagens/gladiador-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/gladiador.png",
      ativo: false
    },
    {
      id: 15,
      nome: "mercenaria",
      foto: "https://arquivos.wizoomplay.com/images/personagens/mercenaria-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/mercenaria.png",
      ativo: false
    },
    {
      id: 16,
      nome: "mimico",
      foto: "https://arquivos.wizoomplay.com/images/personagens/mimico-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/mimico.png",
      ativo: false
    },
    {
      id: 17,
      nome: "rei_cao",
      foto: "https://arquivos.wizoomplay.com/images/personagens/rei-cao-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/rei-cao.png",
      ativo: false
    },
    {
      id: 18,
      nome: "seguranca",
      foto: "https://arquivos.wizoomplay.com/images/personagens/seguranca-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/seguranca.png",
      ativo: false
    },
    {
      id: 19,
      nome: "widneizao",
      foto: "https://arquivos.wizoomplay.com/images/personagens/widneizao-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/widneizao.png",
      ativo: false
    },
    {
      id: 20,
      nome: "eduardinho",
      foto: "https://arquivos.wizoomplay.com/images/personagens/eduardinho-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/eduardinho.png",
      ativo: false
    },
    {
      id: 21,
      nome: "gilbertinho",
      foto: "https://arquivos.wizoomplay.com/images/personagens/gilbertinho-foto.png",
      img: "https://arquivos.wizoomplay.com/images/personagens/gilbertinho.png",
      ativo: false
    }
  ]