import Vue from 'vue'
import VueRouter from 'vue-router'

/* IMPORTS */

//
// DOCS -----
//

// import TermosDeUso from '../components/web/documentos/TermosDeUso'
// import PoliticaDePrivacidade from '../components/web/documentos/PoliticaDePrivacidade'

//
// WEB -----
//

import Index from '../components/web/index/Index'
import Erro from '../components/global/erro/erro'
// import Widnei from '../components/web/widnei/Widnei'
// import Entrar from '../components/web/entrar/Entrar'
// import Whatsapp from '../components/web/whatsapp/Whatsapp'
// import ComboStart from '../components/web/comboStart/ComboStart'

// // Captura CERA
// import CapturaProximoNivel from '../components/web/capturas/proximoNivel/ProximoNivel'
// import ObrigadoProximoNivel from '../components/web/capturas/proximoNivel/Obrigado'
// import JornadaAssumaComandoUm from '../components/web/capturas/jornadaAssumaComando/JornadaAssumaComandoUm'
// import JornadaAssumaComandoUmObrigado from '../components/web/capturas/jornadaAssumaComando/ObrigadoUm'
// import JornadaAssumaComandoDois from '../components/web/capturas/jornadaAssumaComando/JornadaAssumaComandoDois'
// import JornadaAssumaComandoDoisObrigado from '../components/web/capturas/jornadaAssumaComando/ObrigadoDois'
// import JornadaAssumaComandoTres from '../components/web/capturas/jornadaAssumaComando/JornadaAssumaComandoTres'

// import JornadaAssumaComandoAula1 from '../components/web/landingPages/jornadaAssumaComando/aula1/Aula1'
// import JornadaAssumaComandoAula2 from '../components/web/landingPages/jornadaAssumaComando/aula2/Aula2'
// import JornadaAssumaComandoAula3 from '../components/web/landingPages/jornadaAssumaComando/aula3/Aula3'
// import JornadaAssumaComandoAula4 from '../components/web/landingPages/jornadaAssumaComando/aula4/Aula4'

// //
// // CURSOS -----
// //

// // Aplicador de Películas
// import WorkshopAplicadorDePeliculas from '../components/web/capturas/workshopAplicadorDePeliculas/WorkshopAplicadorDePeliculas'
// import WorkshopAplicadorDePeliculasObrigado from '../components/web/capturas/workshopAplicadorDePeliculas/Obrigado'

// // Polidor de Elite
// import LpPolidorDeElite from '../components/web/landingPages/polidorDeElite/PolidorDeElite'
// import LpPolidorDeEliteVip from '../components/web/landingPages/polidorDeEliteVip/PolidorDeEliteVip'
// import LpPolidorDeEliteOferta from '../components/web/landingPages/polidorDeEliteOferta/PolidorDeEliteOferta'

// // Lavagem de Técnica
// import LpLavagemTecnica from '../components/web/landingPages/lavagemTecnica/LavagemTecnica'
// import LpLavagemTecnicaDois from '../components/web/landingPages/lavagemTecnicaDois/LavagemTecnicaDois'
// import LpLavagemTecnicaTres from '../components/web/landingPages/lavagemTecnicaTres/LavagemTecnicaTres'
// import WorkshopLavagemTecnica from '../components/web/capturas/workshopLavagemTecnica/WorkshopLavagemTecnica'

// // Especialista em duas rodas
// import LpEspecialistaEmDuasRodas from '../components/web/landingPages/especialistaEmDuasRodas/EspecialistaEmDuasRodas'

// // Estetica campea
// import LpEsteticaCampea from '../components/web/landingPages/esteticaCampea/EsteticaCampea'

// // Gestão e marketing automotivo
// import LpGestaoMarketingAutomotivo from '../components/web/landingPages/gestaoMarketingAutomotivo/GestaoMarketingAutomotivo'

// // Higienização extrema
// import LpHigienizacaoExtrema from '../components/web/landingPages/higienizacaoExtrema/HigienizacaoExtrema'

// // Lavagem de motor
// import LpLavagemDeMotor from '../components/web/landingPages/lavagemDeMotor/LavagemDeMotor'

// // Mestres dos faróis
// import LpMestresDosFarois from '../components/web/landingPages/mestresDosFarois/MestresDosFarois'

// // Semana do polidor
// import LpSemanaDoPolidor from '../components/web/landingPages/semanaDoPolidor/SemanaDoPolidor'

// // Aplicador de Peliculas Automotivas
// import LpAplicadorDePeliculasAutomotivas from '../components/web/landingPages/aplicadorDePeliculasAutomotivas/AplicadorDePeliculasAutomotivas'

// // Precificação
// import LpPrecificacao from '../components/web/landingPages/precificacao/Precificacao'

// // Precificação
// import LpPilaresDaEsteticaAutomotiva from '../components/web/landingPages/pilaresDaEsteticaAutomotiva/PilaresDaEsteticaAutomotiva'

Vue.use(VueRouter)

const routes = [
  //
  // WEB
  //

  {
    path: '*',
    redirect: "/erro"
  },
  // {
  //   path: '/entrar',
  //   component: Entrar,
  //   beforeEnter(to, from, next){
  //     location.href = 'https://app.wizoomplay.com/entrar'
  //   }
  // },
  {
    path: '/',
    name: "index",
    component: Index,
    meta: {
      title: 'Wizoom Play'
    }
  },
  {
    path: '/erro',
    name: "erro",
    component: Erro,
    meta: {
      title: 'Erro ● Wizoom Play'
    }
  },
  // {
  //   path: '/widnei',
  //   name: "widnei",
  //   component: Widnei,
  //   meta: {
  //     title: 'Widnei Wizoom'
  //   }
  // },
  // {
  //   path: '/combostart',
  //   name: "combostart",
  //   component: ComboStart,
  //   meta: {
  //     title: 'Combo Start'
  //   }
  // },

  // //
  // // WEB documentos
  // //

  // {
  //   path: '/documentos/termos-de-uso',
  //   name: "termosDeUso",
  //   component: TermosDeUso,
  //   meta: {
  //     title: 'Termos de Uso ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/documentos/politica-de-privacidade',
  //   name: "politicaDePrivacidade",
  //   component: PoliticaDePrivacidade,
  //   meta: {
  //     title: 'Política de Privacidade ● WizoomPlay'
  //   }
  // },

  // //
  // // WEB Landing Pages
  // //

  // {
  //   path: '/especialista-em-duas-rodas',
  //   name: "lpEspecialistaEmDuasRodas",
  //   component: LpEspecialistaEmDuasRodas,
  //   meta: {
  //     title: 'Especialista em Duas Rodas ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/gestao-e-marketing-automotivo',
  //   name: "lpGestaoMarketingAutomotivo",
  //   component: LpGestaoMarketingAutomotivo,
  //   meta: {
  //     title: 'Gestão e Marketing Automotivo ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/higienizacao-extrema',
  //   name: "lpHigienizacaoExtrema",
  //   component: LpHigienizacaoExtrema,
  //   meta: {
  //     title: 'Higienização Extrema ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/lavagem-de-motor',
  //   name: "lpLavagemDeMotor",
  //   component: LpLavagemDeMotor,
  //   meta: {
  //     title: 'Lavagem de Motor ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/mestres-dos-farois',
  //   name: "lpMestresDosFarois",
  //   component: LpMestresDosFarois,
  //   meta: {
  //     title: 'Mestres dos Faróis ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/polidor-de-elite',
  //   name: "lpPolidorDeElite",
  //   component: LpPolidorDeElite,
  //   meta: {
  //     title: 'Polidor de Elite ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/vip-polidor-de-elite',
  //   name: "lpPolidorDeEliteVip",
  //   component: LpPolidorDeEliteVip,
  //   meta: {
  //     title: 'Polidor de Elite ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/oferta-polidor-de-elite',
  //   name: "lpPolidorDeEliteOferta",
  //   component: LpPolidorDeEliteOferta,
  //   meta: {
  //     title: 'Polidor de Elite ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/semana-do-polidor',
  //   name: 'LpSemanaDoPolidor',
  //   component: LpSemanaDoPolidor,
  //   meta: {
  //     title: 'Semana do Polidor ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/workshop-estetica-campea',
  //   name: 'LpEsteticaCampea',
  //   component: LpEsteticaCampea,
  //   meta: {
  //     title: 'Estética campeã ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/lavagem-tecnica',
  //   name: 'LpLavagemTecnica',
  //   component: LpLavagemTecnica,
  //   meta: {
  //     title: 'Lavagem Técnica ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/lavagem-tecnica-dois',
  //   name: 'LpLavagemTecnicaDois',
  //   component: LpLavagemTecnicaDois,
  //   meta: {
  //     title: 'Lavagem Técnica ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/lavagem-tecnica-tres',
  //   name: 'LpLavagemTecnicaTres',
  //   component: LpLavagemTecnicaTres,
  //   meta: {
  //     title: 'Lavagem Técnica ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/aplicador-de-peliculas-automotivas',
  //   name: 'LpAplicadorDePeliculasAutomotivas',
  //   component: LpAplicadorDePeliculasAutomotivas,
  //   meta: {
  //     title: 'Aplicador de Películas Automotivas ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/precificacao',
  //   name: 'LpPrecificacao',
  //   component: LpPrecificacao,
  //   meta: {
  //     title: 'Aprenda a precificar ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/estetica-automotiva-pilares',
  //   name: 'LpPilaresDaEsteticaAutomotiva',
  //   component: LpPilaresDaEsteticaAutomotiva,
  //   meta: {
  //     title: 'Pilares da Estética Automotiva'
  //   }
  // },

  // //
  // // WEB Capturas
  // //

  // {
  //   path: '/workshop-lavagem-tecnica',
  //   name: 'WorkshopLavagemTecnica',
  //   component: WorkshopLavagemTecnica,
  //   meta: {
  //     title: 'Workshop Lavagem Técnica ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/workshop-aplicador-de-peliculas',
  //   name: 'WorkshopAplicadorDePeliculas',
  //   component: WorkshopAplicadorDePeliculas,
  //   meta: {
  //     title: 'Workshop Aplicador de Películas ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/workshop-aplicador-de-peliculas/obrigado',
  //   name: 'WorkshopAplicadorDePeliculasObrigado',
  //   component: WorkshopAplicadorDePeliculasObrigado,
  //   meta: {
  //     title: 'Participação Confirmada ● WizoomPlay'
  //   }
  // },
  // {
  //   path: '/proximo-nivel',
  //   name: 'capturaProximoNivel',
  //   component: CapturaProximoNivel,
  //   meta: {
  //     title: 'O seu próximo nível'
  //   }
  // },
  // {
  //   path: '/proximo-nivel/obrigado',
  //   name: 'obrigadoProximoNivel',
  //   component: ObrigadoProximoNivel,
  //   meta: {
  //     title: 'Você está no próximo nível'
  //   }
  // },
  // {
  //   path: '/jornada-assuma-o-comando-1',
  //   name: 'JornadaAssumaComandoUm',
  //   component: JornadaAssumaComandoUm,
  //   meta: {
  //     title: 'Jornada Assuma o Comando'
  //   }
  // },
  // {
  //   path: '/jornada-assuma-o-comando-2',
  //   name: 'JornadaAssumaComandoDois',
  //   component: JornadaAssumaComandoDois,
  //   meta: {
  //     title: 'Jornada Assuma o Comando'
  //   }
  // },
  // {
  //   path: '/jornada-assuma-o-comando-3',
  //   name: 'JornadaAssumaComandoTres',
  //   component: JornadaAssumaComandoTres,
  //   meta: {
  //     title: 'Jornada Assuma o Comando'
  //   }
  // },
  // {
  //   path: '/jornada-assuma-o-comando-1/obrigado',
  //   name: 'JornadaAssumaComandoUmObrigado',
  //   component: JornadaAssumaComandoUmObrigado,
  //   meta: {
  //     title: 'Jornada Assuma o Comando'
  //   }
  // },
  // {
  //   path: '/jornada-assuma-o-comando-2/obrigado',
  //   name: 'JornadaAssumaComandoDoisObrigado',
  //   component: JornadaAssumaComandoDoisObrigado,
  //   meta: {
  //     title: 'Jornada Assuma o Comando'
  //   }
  // },
  // // Aulas da Jornada
  // {
  //   path: '/jornada-assuma-o-comando/aula-1',
  //   name: 'JornadaAssumaComandoAula1',
  //   component: JornadaAssumaComandoAula1,
  //   meta: {
  //     title: 'AULA 1 - Jornada Assuma o Comando'
  //   }
  // },
  // {
  //   path: '/jornada-assuma-o-comando/aula-2',
  //   name: 'JornadaAssumaComandoAula2',
  //   component: JornadaAssumaComandoAula2,
  //   meta: {
  //     title: 'AULA 2 - Jornada Assuma o Comando'
  //   }
  // },
  // {
  //   path: '/jornada-assuma-o-comando/aula-3',
  //   name: 'JornadaAssumaComandoAula3',
  //   component: JornadaAssumaComandoAula3,
  //   meta: {
  //     title: 'AULA 3 - Jornada Assuma o Comando'
  //   }
  // },
  // {
  //   path: '/jornada-assuma-o-comando/aula-4',
  //   name: 'JornadaAssumaComandoAula4',
  //   component: JornadaAssumaComandoAula4,
  //   meta: {
  //     title: 'AULA 4 - Jornada Assuma o Comando'
  //   }
  // },
  // {
  //   path: '/whatsapp',
  //   name: 'Whatsapp',
  //   component: Whatsapp,
  //   meta: {
  //     title: 'Whatsapp'
  //   }
  // },
  // {
  //   path: '/devzap',
  //   beforeEnter: (to, from, next) => {
  //     location.href = 'https://devzapp.com.br/api-engennier/campanha/api/redirect/62b08403dfb9c20001973afb'
  //   }
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(_to, _from, savedPosition) { // to and from do not never usually
    if (savedPosition) return savedPosition
    else return { x: 0, y: 0, behavior: 'smooth' }
  }
})

export default router
