<!-- HTML -->
<template>
  <section class="apresentacao">
    <!-- Imagem -->
    <div class="imagem">
      <img src="https://arquivos.wizoomplay.com/images/site/index/selo.png" alt="">
    </div>
    <!-- Título -->
    <div class="titulo">
      <img src="https://arquivos.wizoomplay.com/images/logo/logo-claro-1.svg" class="logo">
      <h1>AQUI <span>GARANTIMOS A QUALIDADE</span> DO SEU ENSINO</h1>
      <p>O mercado de estética automotiva é fácil de entrar, mas é difícil de ficar.</p>
      <p>Por isso, o nosso compromisso com você aqui na Wizoom Play é garantir que terá o acompanhamento necessário para crescer e evoluir seu negócio.</p>
      <p>Conte com a gente na sua caminhada. Entre para o time Wizoom Play.</p>
    </div>
    <!-- Blush -->
    <img class="blush um" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
    <img class="blush dois" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
  </section>
</template>

<!-- JS -->
<script>
import Icones from '../../../global/icones/Icones.vue'
export default {
  components: {
    Icones
  },
  methods: {
    rolarPara(id) {
      const sectionFotos = document.querySelector(id)
      window.scrollTo({
        top: sectionFotos.offsetTop - 50,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<!-- CSS -->
<style scoped>
  section.apresentacao {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 80px 50px;
    background-color: var(--cor-escuro-1);
  }
  /* Título */
  .titulo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 700px;
    padding: 0 0 0 50px;
    z-index: 2;
  }
  .titulo img.logo {
    width: 100%;
    max-width: 200px;
    margin: 0 0 15px 0;
  }
  .titulo h1 {
    font-family: var(--bold);
    font-size: clamp(1.6rem, 3vw, 2rem);
    color: var(--cor-branco);
    line-height: 1.2;
    width: 100%;
    max-width: 500px;
  }
  .titulo h1 span {
    color: var(--cor-wizoomplay);
  }
  .titulo p {
    font-family: var(--regular);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    line-height: 1.5;
    padding: 10px 0 0 0;
  }
  .titulo button {
    display: flex;
    align-items: center;
    font-family: var(--bold);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-escuro-1);
    background-color: var(--cor-wizoomplay);
    padding: 15px 20px;
    border-radius: 10px;
    transition: all 0.3s;
  }
  .titulo button svg {
    width: 20px;
    min-width: 20px;
    fill: var(--cor-escuro-1);
    margin: 0 10px 0 0;
  }
  .titulo button:hover {
    background-color: var(--cor-branco);
  }
  .titulo a {
    display: flex;
    align-items: center;
    font-family: var(--bold);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    background-color: transparent;
    transition: all 0.3s;
    margin: 15px 0 0 0;
  }
  .titulo a svg {
    width: 15px;
    min-width: 15px;
    fill: var(--cor-branco);
    margin: 0 10px 0 0;
  }
  .titulo a:hover {
    text-shadow: 0 0 10px var(--cor-branco);
  }
  /* Vídeo */
  .imagem {
    z-index: 2;
  }
  .imagem img {
    width: 100%;
    max-width: 400px;
  }
  /* IMG blush */
  img.blush {
    width: 1000px;
    position: absolute;
    z-index: 1;
    animation: zoomBlush 10s linear infinite;
  }
  img.blush.um {
    opacity: 0.3;
    bottom: -500px;
    left: -300px;
  }
  img.blush.dois {
    opacity: 0.3;
    top: -500px;
    right: -100px;
  }
  @keyframes zoomBlush {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    section.apresentacao {
      flex-direction: column;
      padding: 50px 20px;
    }
    /* Título */
    .titulo {
      align-items: center;
      text-align: center;
      padding: 30px 0 0 0;
    }
    .titulo img.logo {
      max-width: 120px;
    }
    /* Vídeo */
    .imagem {
      z-index: 2;
    }
    .imagem img {
      max-width: 250px;
    }
    /* IMG blush */
    img.blush.um {
      bottom: -600px;
      left: -400px;
    }
    img.blush.dois {
      top: -600px;
      right: -400px;
    }
  }
</style>
