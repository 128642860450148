import { api } from './axios'

export default {

  receberPacotes() {
    const method = "GET"
    const url = 'pacotes'

    return api(method, url, null)
  },

  receberPrecos(idPacote) {
    const method = "GET"
    const url = `precos/${idPacote}`

    return api(method, url, null)
  }
}
