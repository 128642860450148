<!-- HTML -->
<template>
  <section id="cursos">
    <!-- Titulo -->
    <div class="titulo">
      <h3>Agora é a sua vez…</h3>
      <h2>Escolha seu treinamento e venha crescer com a gente!</h2>
    </div>
    <!-- Curso -->
    <div class="curso" v-for="curso in listaCursos" :key="curso._id" v-show="dadosCurso?.find(curso => curso?._id === id)">
      <img class="logo" :src="logoCurso(curso)">
      <div class="texto">
        <p>{{ filtrarDescricao(curso) }}</p>
        <button type="button" :style="corBotao(curso)" @click="irParaLP(nomeCursoParaUrl(curso))">{{ textoBotao(curso) }}</button>
      </div>
      <div class="capa" :style="`background-image: url(${bannerCurso(curso)})`"></div>
      <img class="blush" :src="`${blushCurso(curso)}`">
    </div>
  </section>
</template>

<!-- JS -->
<script>
import apiCursos from '../../../../api-web/api-curso.js'
export default {
  data() {
    return {
      cursos: [],
      dadosCurso: [
        {
          "id": "6150958c239fb251a3409055",
          "descricao": "Curso completo de polimento e vitrificação que te ensina o serviço mais técnico de uma estética automotiva do básico ao avançado em +140 aulas.",
          "textoBotao": "Quero ser um Polidor de Elite",
          "corBotao": "border: 2px solid #F3E301",
          "blushCurso": "https://arquivos.wizoomplay.com/images/cursos/polidor-de-elite/blush.png",
          "nomeParaUrl": "polidor-de-elite-2"
        },
        {
          "id": "615095a7239fb251a3409059",
          "descricao": "Uma das tendências do mercado automotivo, onde você aumenta o seu lucro com lavagens detalhadas dobrando seu faturamento sem fazer muito esforço.",
          "textoBotao": "Quero aprender lavagem técnica",
          "corBotao": "border: 2px solid #1683E6",
          "blushCurso": "https://arquivos.wizoomplay.com/images/cursos/lavagem-tecnica/blush.png",
          "nomeParaUrl": "lavagem-tecnica"
        },
        {
          "id": "615095ec239fb251a3409063",
          "descricao": "Neste curso você vai aprender a lavar motores de veículos com muita técnica e máxima segurança, dominando o segredos e os melhores produtos para limpeza e proteção.",
          "textoBotao": "Quero dominar a Lavagem",
          "corBotao": "border: 2px solid #EC1D25",
          "blushCurso": "https://arquivos.wizoomplay.com/images/cursos/lavagem-de-motor/blush.png",
          "nomeParaUrl": "lavagem-de-motor"
        },
        {
          "id": "615095cf239fb251a340905f",
          "descricao": "Curso exclusivo de estética em motocicletas para você atuar em um mercado ainda pouco explorado da estética automotiva.",
          "textoBotao": "Quero ser um Especialista",
          "corBotao": "border: 2px solid #E1AA01",
          "blushCurso": "https://arquivos.wizoomplay.com/images/cursos/especialista-em-duas-rodas/blush.png",
          "nomeParaUrl": "especialista-em-2-rodas"
        },
        {
          "id": "6150959c239fb251a3409057",
          "descricao": "Você vai aprender o passo a passo para dominar um dos serviços mais lucrativos de uma estética automotiva cuidando de todo o interior dos veículos",
          "textoBotao": "Quero aprender Higienização",
          "corBotao": "border: 2px solid #48E33B",
          "blushCurso": "https://arquivos.wizoomplay.com/images/cursos/higienizacao-extrema/blush.png",
          "nomeParaUrl": "higienizacao-extrema"
        },
        {
          "id": "615095b4239fb251a340905b",
          "descricao": "Aprenda a efetuar o polimento e revitalização de faróis amarelados removendo imperfeições para proteger a lente, além de proteger faróis novos e sem defeitos.",
          "textoBotao": "Quero ser um Mestre dos Faróis",
          "corBotao": "border: 2px solid #ff7b00",
          "blushCurso": "https://arquivos.wizoomplay.com/images/cursos/mestres-dos-farois/blush.png",
          "nomeParaUrl": "mestre-dos-farois"
        },
        {
          "id": "61509607239fb251a3409067",
          "descricao": "Aprenda a remover, recortar e aplicar fumê/insulfilm e transforme-se em um Aplicador Profissional de Películas Automotivas.",
          "textoBotao": "Quero ser um Aplicador de Películas",
          "corBotao": "border: 2px solid #A5E414",
          "blushCurso": "https://arquivos.wizoomplay.com/images/cursos/aplicador-de-peliculas-automotivas/blush.png",
          "nomeParaUrl": "08-aplicador-de-peliculas"
        },
        {
          "id": "615095c2239fb251a340905d",
          "descricao": "Aqui é onde você vai conhecer a metodologia por trás das grandes estéticas automotivas para atrair mais clientes, vender mais serviços e ter mais lucro.",
          "textoBotao": "Quero ir para o próximo nível",
          "corBotao": "border: 2px solid #E31A22",
          "blushCurso": "https://arquivos.wizoomplay.com/images/cursos/gestao-e-marketing-automotivo/blush.png",
          "nomeParaUrl": "gestao-e-marketing"
        }
      ]
    }
  },
  computed: {
    listaCursos() {
      return this.cursos?.filter(curso => curso?._id !== '615095f9239fb251a3409065')
    }
  },
  methods: {
    irParaLP(curso) {
      const url = `https://wizoomplay.me/${curso}`
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.click()
    },

    // LISTAR CURSOS
    pegarCursos() {
      apiCursos.pegarCursosParaLandingPage().then(resp => {
        const cursos = resp.data.body

        // REMOVER CURSO WORKSHOP DA LISTA
        const indexWorkshop = cursos?.indexOf(cursos?.find(curso => curso._id === '61ec2bd03568e6aacd20d6b4'))
        this.cursos = cursos?.slice(0, indexWorkshop)
      })
    },

    logoCurso(curso) {
      return curso?.personalizacao?.imagemLogo
    },

    bannerCurso(curso) {
      return curso?.personalizacao?.imagemBanner
    },

    precoParcelado(curso) {
      const valor = curso?.valorParcelado?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
      return `Por 12x de ${valor}`
    },

    filtrarDescricao(curso) {
      const idCurso = curso?._id
      const descricao = this.dadosCurso?.filter(descricao => descricao?.id === idCurso)
      return descricao[0]?.descricao
    },

    textoBotao(curso) {
      const idCurso = curso?._id
      const descricao = this.dadosCurso?.filter(descricao => descricao?.id === idCurso)
      return descricao[0]?.textoBotao
    },

    corBotao(curso) {
      const idCurso = curso?._id
      const descricao = this.dadosCurso?.filter(descricao => descricao?.id === idCurso)
      return descricao[0]?.corBotao
    },

    nomeCursoParaUrl(curso) {
      const idCurso = curso?._id
      const descricao = this.dadosCurso?.filter(descricao => descricao?.id === idCurso)
      return descricao[0]?.nomeParaUrl
    },

    blushCurso(curso) {
      const idCurso = curso?._id
      const descricao = this.dadosCurso?.filter(descricao => descricao?.id === idCurso)
      return descricao[0]?.blushCurso
    },
  },
  created() {
    this.pegarCursos()
  }
}
</script>

<!-- CSS -->
<style scoped>
  section#cursos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--cor-escuro-1);
    background: linear-gradient(to top, var(--cor-escuro-0), var(--cor-escuro-1));
    padding: 50px;
  }
  /* Título */
  .titulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 0 30px 0;
  }
  .titulo h3 {
    font-family: var(--bold);
    font-size: clamp(1.6rem, 3vw, 2rem);
    color: var(--cor-wizoomplay);
    background-color: var(--cor-escuro-0);
    padding: 10px;
    border-radius: 5px;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0 0 10px 0;
  }
  .titulo h2 {
    font-family: var(--bold);
    font-size: clamp(1.6rem, 3vw, 2rem);
    color: var(--cor-branco);
    line-height: 1.2;
    text-transform: uppercase;
    width: 100%;
    max-width: 800px;
  }
  .titulo h2 span {
    color: var(--cor-wizoomplay);
  }
  /* Curso */
  .curso {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1000px;
    margin: 0 0 20px 0;
    background-color: var(--cor-escuro-0);
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    min-height: 300px;
  }
  /* Curso Logo */
  .curso img.logo {
    width: 100%;
    max-width: 200px;
    margin: 0 30px 0 60px;
    z-index: 2;
  }
  .curso img.blush {
    position: absolute;
    bottom: -500px;
    left: -400px;
    width: 1000px;
  }
  /* Curso > Título */
  .curso .texto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 30px 30px 0;
    z-index: 2;
  }
  .curso .texto p {
    font-family: var(--regular);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    line-height: 1.5;
    width: 100%;
    max-width: 500px;
  }
  .curso .texto p span {
    font-family: var(--bold);
  }
  .curso .texto button {
    font-family: var(--bold);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    background-color: transparent;
    padding: 12px;
    border-radius: 10px;
    transition: all 0.3s;
    margin: 10px 0 0 0;
  }
  .curso .texto button:hover {
    background-color: var(--cor-escuro-0);
    box-shadow: var(--sombra);
  }
  /* Curso > Capa */
  .curso .capa {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0.3;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    section#cursos {
      padding: 40px 20px;
    }
    .titulo {
      padding: 0 0 20px 0;
    }
    .titulo h3 {
      font-size: clamp(1.2rem, 3vw, 1.6rem);
    }
    .titulo h2 {
      font-size: clamp(1.3rem, 3vw, 1.7rem);
    }
    /* Curso */
    .curso {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 20px 0;
      min-height: auto;
    }
    /* Curso Logo */
    .curso img.logo {
      width: 100%;
      max-width: 200px;
      margin: 40px 0 0 20px;
      z-index: 2;
    }
    .curso img.blush {
      position: absolute;
      bottom: auto;
      top: -400px;
      left: -400px;
      width: 800px;
    }
    /* Curso > Título */
    .curso .texto {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 20px 40px 20px;
    }
    /* Curso > Capa */
    .curso .capa {
      opacity: 0.2;
    }
  }
</style>
