<!-- HTML -->
<template>
  <div class="alerta" :class="{mostrar: visivel, negativo: classe === 'negativo', positivo: classe === 'positivo'}" @click="fecharAlerta">
    <div class="conteudo">
      <Icones nome="atencao"/>
      <p>{{ mensagem }}</p>
      <div class="animacao"></div>
    </div>
  </div>
</template>

<!-- JS -->
<script>
import { mapMutations, mapState } from 'vuex'
import Icones from '../icones/Icones.vue';
export default {
  components: {
    Icones
  },
  computed: {
    ...mapState({
      visivel: state => state.alerta.visivel,
      mensagem: state => state.alerta.mensagem,
      classe: state => state.alerta.classe
    })
  },
  methods: {
    ...mapMutations("alerta", ["ALERTA_FECHAR"]),

    fecharAlerta(){
      this.ALERTA_FECHAR()
    }
  }
}
</script>

<!-- CSS -->
<style scoped>
  .alerta {
    display: none;
    width: 100%;
    max-width: 30%;
    position: fixed;
    top: 50px;
    right: 50%;
    transform: translate(50%, 0);
    cursor: pointer;
    z-index: 50;
  }
  .alerta.mostrar {
    display: flex;
  }
  .alerta.positivo .conteudo {
    background-color: var(--cor-verde);
  }
  .alerta.negativo .conteudo {
    background-color: var(--cor-vermelho);
  }
  /* Conteudo */
  .conteudo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cor-escuro-4);
    box-shadow: var(--sombra);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    overflow: hidden;
    animation: aparecer 0.2s linear;
    pointer-events: none;
    position: relative;
  }
  @keyframes aparecer {
    0% {
      opacity: 0;
      visibility: hidden;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
  .conteudo .animacao {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--cor-preto-transparente);
    z-index: 51;
    top: 0;
    right: 0;
    animation: aparecendo 7s linear;
  }
  @keyframes aparecendo {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  .conteudo p {
    font-family: var(--bold);
    font-size: clamp(0.7rem, 3vw, 0.9rem);
    color: var(--cor-branco);
    line-height: 1.5;
    margin-right: auto;
    z-index: 52;
  }
  .conteudo svg {
    width: 30px;
    min-width: 30px;
    fill: var(--cor-branco);
    margin: 0 15px 0 0;
    z-index: 52;
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    .alerta {
      max-width: calc(100% - 60px);
      top: 30px;
    }
    .conteudo svg {
      width: 20px;
      min-width: 20px;
      margin: 0 10px 0 0;
    }
  }
</style>
