<!-- HTML -->
<template>
  <div class="erro">
    <!-- Logo -->
    <img class="logo" src="https://arquivos.wizoomplay.com/images/logo/logo-erro.svg"/>
    <p>Parece que essa tela não existe. <span>Tente novamente ou entre em contato</span> com o suporte.</p>
    <button type="button" class="voltar" @click="voltarParaInicio()">
      Voltar ao início
    </button>
  </div>
</template>

<!-- JS -->
<script>
export default {
  methods: {
    voltarParaInicio(){
      this.$router.push("/")
    }
  }
}
</script>

<!-- CSS -->
<style scoped>
  .erro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 50%;
    padding: 80px 40px 80px 80px;
    margin-right: auto;
    min-height: 100vh;
    background-color: var(--cor-escuro-0);
  }
  img.logo {
    width: 100%;
    max-width: 250px;
  }
  p {
    font-family: var(--regular);
    font-size: clamp(0.8rem, 3vw, 1rem);
    color: var(--cor-branco);
    line-height: 1.5;
    text-align: center;
    width: 100%;
    max-width: 450px;
    padding: 20px 0 20px 0;
  }
  p span {
    font-family: var(--bold);
  }
  button.voltar {
    font-family: var(--bold);
    font-size: clamp(0.8rem, 3vw, 1rem);
    color: var(--cor-vermelho);
    background-color: transparent;
    transition: all 0.3s;
  }
  button.voltar:hover {
    color: var(--cor-vermelho-escuro);
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    .erro {
      max-width: 100%;
      padding: 120px 30px;
      min-height: auto;
    }
    img.logo {
      max-width: 200px;
    }
    p {
      max-width: 300px;
    }
  }
</style>
