<template>
  <div id="app">
    <Alerta/>
    <router-view/>
  </div>
</template>
<script>
import Alerta from './components/global/alerta/Alerta.vue'
// import balaoChaport from './helpers/balaoChaport'
// import helperTransformarLink from './helpers/transformar-links'

export default {
  components: {
    Alerta
  },
  // watch: {
  //   $route(to, from) {
  //     if (to.path !== from.path) { // previne loop
  //       this.$router.replace({ query: from.query })
  //     }
  //     else return
  //   }
  // },
  methods: {
    // mostrarBalaoChaport() {
    //   const nome = 'VISITANTE'
    //   const email = ''

    //   balaoChaport.iniciarChaport(nome, email)
    //   balaoChaport.abrirChaport()
    // },
  },
  created(){
    // this.mostrarBalaoChaport()

    if(location.pathname === '/entrar') location.assign('https://app.wizoomplay.com/entrar')
    else return
  },
  // mounted() {
  //   helperTransformarLink.transformarTodosOsLinks()
  // },
  // updated() {
  //   helperTransformarLink.transformarTodosOsLinks()
  // },
}
</script>

<style>
  @import url('./css/fontes.css');
  @import url('./css/normalizar.css');
  @import url('./css/scrollbar.css');
  @import url('./css/variaveis.css');
  @import url('./css/animacoes.css');
  @import url('./css/elementos.css');
</style>
