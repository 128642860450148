export default {
  state: {
    mostrarModalConteudoPolidorDeElite: false,
    mostrarModalConteudoHigienizacaoExtrema: false,
    mostrarModalConteudoLavagemTecnica: false,
    mostrarModalConteudoEspecialistaEmDuasRodas: false,
    mostrarModalConteudoLavagemDeMotor: false,
    mostrarModalConteudoMestresDosFarois: false,
    mostrarModalConteudoGestaoMarketingAutomotivo: false,
  },
  mutations: {
    MOSTRAR_MODAL_CONTEUDO_POLIDOR_DE_ELITE(state, payload) {
      state.mostrarModalConteudoPolidorDeElite = payload
    },
    MOSTRAR_MODAL_CONTEUDO_HIGIENIZACAO_EXTREMA(state, payload) {
      state.mostrarModalConteudoHigienizacaoExtrema = payload
    },
    MOSTRAR_MODAL_CONTEUDO_LAVAGEM_TECNICA(state, payload) {
      state.mostrarModalConteudoLavagemTecnica = payload
    },
    MOSTRAR_MODAL_CONTEUDO_ESPECIALISTA_EM_DUAS_RODAS(state, payload) {
      state.mostrarModalConteudoEspecialistaEmDuasRodas = payload
    },
    MOSTRAR_MODAL_CONTEUDO_LAVAGEM_DE_MOTOR(state, payload) {
      state.mostrarModalConteudoLavagemDeMotor = payload
    },
    MOSTRAR_MODAL_CONTEUDO_MESTRES_DOS_FAROIS(state, payload) {
      state.mostrarModalConteudoMestresDosFarois = payload
    },
    MOSTRAR_MODAL_CONTEUDO_GESTAO_MARKETING_AUTOMOTIVO(state, payload) {
      state.mostrarModalConteudoGestaoMarketingAutomotivo = payload
    },
  },
  namespaced: true
}
