export default {
  state: {
    mostrarLoadingGlobal: true,
  },
  mutations: {
    LOADING_MOSTRAR(state, payload){
      state.mostrarLoadingGlobal = payload
    }
  },
  namespaced: true
}
