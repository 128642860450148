<!-- HTML -->
<template>
  <section class="apresentacao">
    <!-- Título -->
    <div class="titulo">
      <img src="https://arquivos.wizoomplay.com/images/logo/logo-claro-1.svg" class="logo">
      <h1><span>CONSTRUIR SUA CARREIRA</span> NA ESTÉTICA AUTOMOTIVA AGORA <span>FICOU MUITO</span> MAIS FÁCIL!</h1>
      <p>Somos a primeira e única escola online de Estética Automotiva do Brasil que te leva do zero ao avançado para dominar todos os pilares desse mercado altamente lucrativo</p>
      <button type="button" @click="rolarPara('section#cursos')"><Icones nome="cursos"/>Quero ver os cursos</button>
      <a target="_blank" href="https://app.wizoomplay.com"><Icones nome="play"/>Já sou aluno</a>
    </div>
    <!-- Principal -->
    <div class="video">
      <div class="tamanho">
        <iframe id="panda-63b7d4ac-55fa-4087-aebc-27a6c620d909" src="https://player-vz-a9153df5-756.tv.pandavideo.com.br/embed/?v=63b7d4ac-55fa-4087-aebc-27a6c620d909" style="border:none;position:absolute;top:0;left:0;" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowfullscreen=true></iframe>
      </div>
      <button type="button" @click="rolarPara('section#cursos')"><Icones nome="cursos"/>Quero ver os cursos</button>
      <a target="_blank" href="https://app.wizoomplay.com"><Icones nome="play"/>Já sou aluno</a>
    </div>
    <!-- Blush -->
    <img class="blush um" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
    <img class="blush dois" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
  </section>
</template>

<!-- JS -->
<script>
import Icones from '../../../global/icones/Icones.vue'
export default {
  components: {
    Icones
  },
  methods: {
    rolarPara(id) {
      const sectionFotos = document.querySelector(id)
      window.scrollTo({
        top: sectionFotos.offsetTop - 50,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<!-- CSS -->
<style scoped>
  section.apresentacao {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 50px;
  }
  /* Título */
  .titulo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    z-index: 2;
  }
  .titulo img.logo {
    width: 100%;
    max-width: 200px;
    margin: 0 0 15px 0;
  }
  .titulo h1 {
    font-family: var(--bold);
    font-size: clamp(1.6rem, 3vw, 2rem);
    color: var(--cor-branco);
    line-height: 1.2;
    width: 100%;
    max-width: 550px;
  }
  .titulo h1 span {
    color: var(--cor-wizoomplay);
  }
  .titulo p {
    font-family: var(--regular);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    line-height: 1.5;
    padding: 10px 0 20px 0;
    width: 100%;
    max-width: 600px;
  }
  .titulo button {
    display: flex;
    align-items: center;
    font-family: var(--bold);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-escuro-1);
    background-color: var(--cor-wizoomplay);
    padding: 15px 20px;
    border-radius: 10px;
    transition: all 0.3s;
  }
  .titulo button svg {
    width: 20px;
    min-width: 20px;
    fill: var(--cor-escuro-1);
    margin: 0 10px 0 0;
  }
  .titulo button:hover {
    background-color: var(--cor-branco);
  }
  .titulo a {
    display: flex;
    align-items: center;
    font-family: var(--bold);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    background-color: transparent;
    transition: all 0.3s;
    margin: 15px 0 0 0;
  }
  .titulo a svg {
    width: 15px;
    min-width: 15px;
    fill: var(--cor-branco);
    margin: 0 10px 0 0;
  }
  .titulo a:hover {
    text-shadow: 0 0 10px var(--cor-branco);
  }
  /* Vídeo */
  .video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    z-index: 2;
  }
  .video button {
    display: none;
    align-items: center;
    font-family: var(--bold);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-escuro-1);
    background-color: var(--cor-wizoomplay);
    padding: 15px 20px;
    border-radius: 10px;
    transition: all 0.3s;
  }
  .video button svg {
    width: 20px;
    min-width: 20px;
    fill: var(--cor-escuro-1);
    margin: 0 10px 0 0;
  }
  .video button:hover {
    background-color: var(--cor-branco);
  }
  .video a {
    display: none;
    align-items: center;
    font-family: var(--bold);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    background-color: transparent;
    transition: all 0.3s;
    margin: 15px 0 0 0;
  }
  .video a svg {
    width: 15px;
    min-width: 15px;
    fill: var(--cor-branco);
    margin: 0 10px 0 0;
  }
  .video a:hover {
    text-shadow: 0 0 10px var(--cor-branco);
  }
  .video .tamanho {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  .video .tamanho iframe {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    margin: 0 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    background-color: var(--cor-preto);
  }
  /* IMG blush */
  img.blush {
    width: 1000px;
    position: absolute;
    z-index: 1;
    animation: zoomBlush 10s linear infinite;
  }
  img.blush.um {
    opacity: 0.2;
    bottom: -300px;
    left: -300px;
  }
  img.blush.dois {
    opacity: 0.5;
    top: -200px;
    right: -100px;
  }
  @keyframes zoomBlush {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    section.apresentacao {
      flex-direction: column;
      padding: 50px 20px;
    }
    /* Título */
    .titulo {
      width: 100%;
    }
    .titulo img.logo {
      max-width: 150px;
    }
    .titulo p {
      padding: 10px 0 20px 0;
    }
    .titulo button {
      display: none;
    }
    .titulo a {
      display: none;
    }
    /* Vídeo */
    .video {
      width: 100%;
      z-index: 2;
    }
    .video button {
      display: flex;
      margin: 15px 0 0 0;
    }
    .video a {
      display: flex;
    }
    /* IMG blush */
    img.blush {
      width: 700px;
      position: absolute;
      z-index: 1;
      animation: zoomBlush 10s linear infinite;
    }
    img.blush.um {
      opacity: 0.2;
      bottom: -400px;
      left: -300px;
    }
    img.blush.dois {
      opacity: 0.2;
      top: -200px;
      right: -200px;
    }
    @keyframes zoomBlush {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.5);
      }
      100% {
        transform: scale(1);
      }
    }
  }
</style>
