<!-- HTML -->
<template>
  <section class="professores">
    <!-- Título -->
    <div class="titulo">
      <h2>Aprenda com quem <span>faz na prática e está no campo de batalha</span> todos os dias...</h2>
    </div>
    <!-- Parte -->
    <div class="parte">
      <img class="personagem" src="https://arquivos.wizoomplay.com/images/site/index/Widnei.png">
      <div class="texto">
        <h3><span>Widnei</span> Eduardo</h3>
        <p>Especialista em estética automotiva. Começou no ramo automotivo há 16 anos. Criou do zero a maior estética automotiva da sua região e já formou milhares de profissionais em seus cursos. É instrutor nos treinamentos Polidor de Elite, Higienização Extrema, Lavagem Técnica, Mestre dos Faróis e Gestão e Marketing.</p>
      </div>
      <img class="blush" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
    </div>
    <!-- Parte -->
    <div class="parte">
      <img class="personagem" src="https://arquivos.wizoomplay.com/images/site/index/Kevyn.png">
      <div class="texto">
        <h3><span>Kevyn</span> Taborda</h3>
        <p>Técnico em estética automotiva e especialista em cuidar da estética em motocicletas. É também instrutor do treinamento Especialista em Duas Rodas aqui na Wizoom Play.</p>
      </div>
      <img class="blush" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
    </div>
    <!-- Parte -->
    <div class="parte">
      <img class="personagem" src="https://arquivos.wizoomplay.com/images/site/index/Felipe.png">
      <div class="texto">
        <h3><span>Felipe</span> Silva</h3>
        <p>Técnico em estética automotiva e líder local da Wizoom Estética, é especialista em lavagem e detalhamento de motor e chassi. Sendo o instrutor no curso Lavagem de Motor e Detalhamento de Chassi.</p>
      </div>
      <img class="blush" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
    </div>
    <!-- Parte -->
    <div class="parte">
      <img class="personagem" src="https://arquivos.wizoomplay.com/images/site/index/Tader.png">
      <div class="texto">
        <h3><span>Tader</span> Aiex</h3>
        <p>O “Mago das Películas Automotivas”, atua no ramo há mais de 19 anos, já tendo executado esse serviço em mais de 10 mil veículos, e na Wizoom Play é instrutor do treinamento Aplicador de Películas Automotivas.</p>
      </div>
      <img class="blush" src="https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png">
    </div>
  </section>
</template>

<!-- JS -->
<script>
export default {
}
</script>

<!-- CSS -->
<style scoped>
  section.professores {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--cor-escuro-0);
    overflow: hidden;
    position: relative;
  }
  /* Título */
  .titulo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 50px 50px 0 50px;
  }
  .titulo h2 {
    font-family: var(--bold);
    font-size: clamp(1.6rem, 3vw, 2rem);
    color: var(--cor-branco);
    line-height: 1.2;
    text-transform: uppercase;
    width: 100%;
    max-width: 800px;
  }
  .titulo h2 span {
    color: var(--cor-wizoomplay);
  }
  /* Parte */
  .parte {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 50px 50px 0 50px;
    position: relative;
    overflow: hidden;
  }
  .parte img.personagem {
    width: 100%;
    max-width: 250px;
    z-index: 2;
  }
  .parte img.blush {
    position: absolute;
    bottom: -500px;
    right: -200px;
    width: 1000px;
    pointer-events: none;
    opacity: 0.5;
    z-index: 1;
  }
  .parte .texto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 50px 50px;
    z-index: 2;
  }
  .parte h3 {
    font-family: var(--bold);
    font-size: clamp(1.2rem, 3vw, 1.5rem);
    color: var(--cor-branco);
    padding: 0 0 10px 0;
  }
  .parte h3 span {
    color: var(--cor-wizoomplay);
  }
  .parte p {
    font-family: var(--regular);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    line-height: 1.5;
  }
  .parte p span {
    font-family: var(--bold);
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    .titulo {
      padding: 40px 20px 0 20px;
      text-align: left;
    }
    .titulo h2 {
      font-size: clamp(1.3rem, 3vw, 1.7rem);
    }
    .parte {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      padding: 40px 20px 0 20px;
    }
    .parte img.personagem {
      max-width: 120px;
    }
    .parte img.blush {
      bottom: -400px;
      right: -300px;
      width: 800px;
      opacity: 0.3;
    }
    .parte .texto {
      padding: 0 20px 40px 0;
    }
  }
</style>
