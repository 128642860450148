<!-- HTML -->
<template>
  <div id="footer">
    <div class="copy">
      <p><img class="logo" src="https://arquivos.wizoomplay.com/images/logo/logo-claro-1.svg"> é um produto exclusivo wizoom. Todos os Direitos reservados.</p>
      <div class="botoes">
        <a href="https://wizoomplay.me/politicas-de-privacidade/" target="_blank">Política de Privacidade</a>
        <a href="https://wizoomplay.me/termos-de-uso/" target="_blank">Termos de Uso</a>
      </div>
    </div>
    <div class="sociais">
      <a href="https://www.youtube.com/channel/UCEkCh_l8c6go8-VMHCUJVtQ" target="_blank">
        <Icones nome="youtube"/>
      </a>
      <a href="https://instagram.com/widneiwizoom/" target="_blank">
        <Icones nome="instagram"/>
      </a>
    </div>
  </div>
</template>

<!-- JS -->
<script>
import Icones from '../icones/Icones.vue';
export default {
  components: {
    Icones
  }
}
</script>

<!-- CSS -->
<style scoped>
  #footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 50px;
    background: var(--cor-escuro-0);
    box-shadow: 0 0 50px 0 var(--cor-escuro-1);
    margin-top: auto;
  }
  /* Copy */
  .copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: auto;
  }
  .copy img {
    width: 100%;
    max-width: 120px;
    margin: 0 5px 0 0;
  }
  .copy p {
    display: flex;
    align-items: center;
    font-family: var(--regular);
    font-size: clamp(0.6rem, 3vw, 0.8rem);
    color: var(--cor-branco);
    line-height: 1.5;
    width: 100%;
  }
  .copy p span {
    font-family: var(--bold);
  }
  .copy .botoes {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
  }
  .copy .botoes a {
    font-family: var(--bold);
    font-size: clamp(0.6rem, 3vw, 0.8rem);
    color: var(--cor-escuro-5);
    margin: 0 15px 0 0;
    transition: all 0.3s;
  }
  .copy .botoes a:hover {
    color: var(--cor-branco);
  }
  /* Sociais */
  .sociais {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sociais a {
    margin: 0 0 0 30px;
  }
  .sociais a svg {
    width: 40px;
    min-width: 40px;
    fill: var(--cor-branco);
    transition: all 0.3s;
    pointer-events: none;
  }
  .sociais a:hover svg {
    fill: var(--cor-escuro-5);
  }
  .sociais a.lecdt {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    margin: 0 60px 0 30px;
    font-family: var(--bold);
    font-size: clamp(0.5rem, 3vw, 0.7rem);
    color: var(--cor-escuro-4);
    transition: all 0.3s;
  }
  .sociais a.lecdt span {
    font-family: var(--bold);
    font-size: clamp(0.8rem, 3vw, 1rem);
  }
  .sociais a.lecdt:hover {
    color: #0000ff;
    text-shadow: 0 0 15px #0000ff;
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    #footer {
      flex-direction: column;
      width: 100%;
      padding: 40px 0;
    }
    /* Copy */
    .copy {
      flex-direction: column;
      align-items: center;
      margin-right: 0;
    }
    .copy img {
      max-width: 130px;
      margin: 0 0 10px 0;
    }
    .copy p {
      flex-direction: column;
      text-align: center;
      width: 100%;
      max-width: 230px;
    }
    .copy .botoes {
      display: flex;
      align-items: center;
      margin: 5px 0 0 0;
    }
    .copy .botoes a {
      margin: 10px 10px;
    }
    /* Sociais */
    .sociais {
      margin: 25px 0 0 0;
      padding: 40px 0 0 0;
      border-top: 2px solid var(--cor-escuro-1);
      width: 100%;
    }
    .sociais a {
      display: none;
    }
    .sociais a:hover svg {
      fill: var(--cor-escuro-5);
    }
    .sociais a.lecdt {
      align-items: center;
      text-align: center;
      margin: 0;
      font-size: clamp(0.7rem, 3vw, 0.9rem);
    }
    .sociais a.lecdt span {
      font-size: clamp(1rem, 3vw, 1.2rem);
    }
  }
</style>
