import { api } from './axios'
import verificarToken from '../api-web/verificar-token-ok'

export default {
  receberPrecos() {
    const token = verificarToken.receberToken()
    const method = "GET"
    const url = 'precos/academy'

    return api(method, url, null, token)
  }
}
