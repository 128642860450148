var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sobre"},[_c('div',{staticClass:"quadros"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"botao"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.rolarPara('section#cursos')}}},[_c('Icones',{attrs:{"nome":"check"}}),_vm._v("Quero construir um negócio lucrativo")],1)])]),_c('img',{staticClass:"blush um",attrs:{"src":"https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png"}}),_c('img',{staticClass:"blush dois",attrs:{"src":"https://arquivos.wizoomplay.com/images/site/index/blush-amarelo.png"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titulo"},[_c('h2',[_vm._v("SAIBA "),_c('span',[_vm._v("O QUE TE ESPERA")]),_vm._v(" NA WIZOOM PLAY")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quadro direita"},[_c('img',{attrs:{"src":"https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-selo.svg"}}),_c('p',[_vm._v("Seja Bem-Vindo à "),_c('b',[_vm._v("maior Escola para a Formação e Desenvolvimento de Profissionais da Estética Automotiva no Brasil.")]),_vm._v(" Já são mais de 14 mil alunos treinados.")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quadro esquerda"},[_c('img',{attrs:{"src":"https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-carro.svg"}}),_c('p',[_vm._v("Aprenda desde realizar serviços como "),_c('b',[_vm._v("polimento, higienização, lavagens técnicas, vitrificação, aplicação de insulfilm/fumê, estética em motocicletas, lavagem de motores")]),_vm._v(" e diversos outros cuidados automotivos.")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quadro direita"},[_c('img',{attrs:{"src":"https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-dinheiro.svg"}}),_c('p',[_vm._v("Até "),_c('b',[_vm._v("treinamentos de vendas, gestão, marketing, contratações,")]),_vm._v(" expansão e aumento dos seus serviços.")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quadro esquerda"},[_c('img',{attrs:{"src":"https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-saco.svg"}}),_c('p',[_vm._v("Você será levado em uma jornada por um ambiente online, "),_c('b',[_vm._v("construído e pensado para você alcançar resultados,")]),_vm._v(" facilitar seu aprendizado e motivar seu crescimento.")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quadro direita"},[_c('img',{attrs:{"src":"https://arquivos.wizoomplay.com/images/site/index/icone-gradiente-escudo.svg"}}),_c('p',[_vm._v("É hora de "),_c('b',[_vm._v("construir um negócio lucrativo")]),_vm._v(" no mercado da Estética Automotiva.")])])
}]

export { render, staticRenderFns }