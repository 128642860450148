<!-- HTML -->
<template>
  <div id="index">
    <SectionApresentacao/>
    <SectionSobre/>
    <SectionVantagens/>
    <SectionProfessores/>
    <SectionCursos/>
    <SectionGarantia/>
    <Footer/>
  </div>
</template>

<!-- JS -->
<script>
import SectionApresentacao from './partials/SectionApresentacao.vue'
import SectionSobre from './partials/SectionSobre.vue'
import SectionVantagens from './partials/SectionVantagens.vue'
import SectionProfessores from './partials/SectionProfessores.vue'
import SectionCursos from './partials/SectionCursos.vue'
import SectionGarantia from './partials/SectionGarantia.vue'
import Footer from '../../global/footer/Footer.vue'
export default {
  components: {
    SectionApresentacao,
    SectionSobre,
    SectionVantagens,
    SectionProfessores,
    SectionCursos,
    SectionGarantia,
    Footer
  }
}
</script>

<!-- CSS -->
<style scoped>
  #index {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
</style>
