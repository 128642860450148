<!-- HTML -->
<template>
  <div class="personagens">
    <!-- Personagem -->
    <img v-if="personagemDestaque" class="personagem" :src="personagemDestaque">
    <img v-if="!personagemDestaque" class="personagem" src="https://arquivos.wizoomplay.com/images/personagens/widneizao.png" >
    <h2>Esses são todos os personagens disponíveis na Wizoomplay:</h2>
    <!-- Slide -->
    <div class="slide" @mousedown="mouseDown($event)" @mouseleave="mouseLeave()" @mousemove="mouseMove($event)" @mouseup="mouseUp()">
      <!-- Button -->
      <button type="button" v-for="personagem in personagens" :key="personagem.id" :class="{ativo: personagem.ativo}" @click="ativarPersonagem(personagem)">
        <img :src="personagem.foto">
      </button>
    </div>
    <!-- Linhas -->
    <div class="linha"></div>
    <div class="linha dois"></div>
    <div class="linha tres"></div>
  </div>
</template>

<!-- JS -->
<script>
import listaPersonagens from '../../../../assets/personagens'
export default {
  data(){
    return {
      personagemDestaque: "",
      efeito: false,
      personagens: listaPersonagens
    }
  },
  methods: {
    ativarPersonagem(personagem){
      if(!personagem) return

      this.personagens.filter(i => i.ativo = false)
      personagem.ativo = true
      this.personagemDestaque = personagem.img
    },

    /* ----------------------- DRAG AND SCROLL --------------------- */
    mouseDown(event){
      const componente = this.$el.querySelector(".slide")
      this.bloqueio = true
      this.coordenadaInicialX = event.pageX - componente.offsetLeft
      this.rolagemX = componente.scrollLeft
    },

    mouseLeave(){
      const componente = this.$el.querySelector(".slide")
      this.bloqueio = false
      componente.classList.remove('drag')
    },

    mouseUp(){
      const componente = this.$el.querySelector(".slide")
      this.bloqueio = false
      componente.classList.remove('drag')
    },

    mouseMove(event){
      const componente = this.$el.querySelector(".slide")
      if (!this.bloqueio) return
      event.preventDefault()

      const x = event.pageX - componente.offsetLeft
      const deslocamento = (x - this.coordenadaInicialX) * 2
      componente.scrollLeft = this.rolagemX - deslocamento
      componente.classList.add('drag')
    }
    /* ---------------------------------------------------------- */
  }
}
</script>

<!-- CSS -->
<style scoped>
  .personagens {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 50%;
    background-color: var(--cor-escuro-1);
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden;
  }
  h2 {
    font-family: var(--bold);
    font-size: clamp(0.8rem, 3vw, 1rem);
    color: var(--cor-branco);
    line-height: 1.5;
    z-index: 3;
    width: 100%;
    max-width: 280px;
    position: absolute;
    bottom: 140px;
    left: 25px;
  }
  /* Linha */
  .linha {
    position: absolute;
    width: 500px;
    min-width: 500px;
    height: 500px;
    min-height: 500px;
    border: 20px solid var(--cor-escuro-2);
    border-radius: 50%;
    z-index: 1;
    animation: zoomLinha 5s linear infinite;
  }
  .linha.dois {
    animation: zoomLinha 2s linear infinite;
    border: 10px solid var(--cor-escuro-2);
  }
  .linha.tres {
    animation: zoomLinha 10s linear infinite;
    border: 5px solid var(--cor-escuro-2);
  }
  /* IMG personagem */
  img.personagem {
    height: 90%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    animation: zoomPersonagem 30s linear infinite;
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
  img.personagem:disabled {
    opacity: 0;
    visibility: hidden;
  }
  /* Slide */
  .slide {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
    margin-top: auto;
    overflow-x: scroll;
    z-index: 2;
    scrollbar-width: none;
  }
  .slide::-webkit-scrollbar {
    display: none;
  }
  .slide.drag {
    cursor: grab;
  }
  .slide button {
    background-color: transparent;
    margin: 0 5px;
  }
  .slide button img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    pointer-events: none;
    border-radius: 50%;
    transition: all 0.3s;
    filter: brightness(50%);
    border: 3px solid transparent;
  }
  .slide button:hover img {
    filter: brightness(60%);
  }
  .slide button.ativo img {
    filter: brightness(100%);
    border: 3px solid var(--cor-branco);
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    h2 {
      width: 100%;
      max-width: 230px;
      bottom: auto;
      top: 30px;
      left: 30px;
      background-color: transparent;
      padding: 0;
    }
    .personagens {
      max-width: 100%;
      min-height: 620px;
      max-height: 620px;
      height: 620px;
      position: relative;
      border: 0px solid transparent;
      border-radius: 0;
    }
    .personagens img.personagem {
      height: 60%;
      margin-top: auto;
    }
    .slide {
      padding: 80px 25px 30px 25px;
      margin-top: 0;
      margin-bottom: auto;
    }
  }
</style>
