import apiPagamentos from '../../../../api-pay/api-pagamento'
import apiAcademy from '../../../../api-pay/api-pagamentos-academy'
export default {
  state: {
    mostrarCheckout: false,
    metodoPagamento: 'cartao',
    precos: []
  },
  mutations: {
    CHECKOUT_MOSTRAR_MODAL(state, payload){
      state.mostrarCheckout = payload
    },
    CHECKOUT_MUDAR_PAGAMENTO(state, payload){
      state.metodoPagamento = payload
    },
    CHECKOUT_RECEBER_PRECOS(state, payload) {
      state.precos = payload
    }
  },
  actions: {
    _receberPrecos({commit}, idPacote) {
      return apiPagamentos.receberPrecos(idPacote)
      .then(resp => {
        commit("CHECKOUT_RECEBER_PRECOS", resp.data.body)
      })
    },

    _receberPrecosAcademy({commit}) {
      return apiAcademy.receberPrecos()
      .then(resp => {
        commit("CHECKOUT_RECEBER_PRECOS", resp.data.body)
      })
    }
  },
  namespaced: true
}
