import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import store_Alerta from '../components/global/alerta/store'
import store_Loading from '../components/global/loading/store'
import store_checkout from '../components/global/checkout/store'
import store_lpPolidorDeElite from '../components/web/landingPages/polidorDeElite/store'
import store_widnei from '../components/web/widnei/store'
import store_pilares from '../components/web/landingPages/pilaresDaEsteticaAutomotiva/store'

const store = new Vuex.Store({
  modules: {
    alerta: store_Alerta,
    loading: store_Loading,
    checkout: store_checkout,
    lpPolidorDeElite: store_lpPolidorDeElite,
    widnei: store_widnei,
    pilares: store_pilares
  }
})

export default store
