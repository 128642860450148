export default {
  state: {
    visivel: false,
    mensagem: "",
    classe: ""
  },
  mutations: {
    ALERTA_MOSTRAR(state, payload){
      state.visivel = payload.visivel
      state.mensagem = payload.mensagem
      state.classe = payload.classe
      setTimeout(() => {
        this.commit("alerta/ALERTA_FECHAR")
      }, 7000);
    },
    ALERTA_FECHAR(state){
      state.visivel = false
      state.mensagem = ""
      state.classe = ""
    }
  },
  namespaced: true
}
