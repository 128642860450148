<!-- HTML -->
<template>
  <div id="erro">
    <SectionErro/>
    <SectionPersonagens/>
  </div>
</template>

<!-- JS -->
<script>
import SectionErro from './partials/SectionErro.vue'
import SectionPersonagens from './partials/SectionPersonagens.vue'
export default {
  components: {
    SectionErro,
    SectionPersonagens,
  }
}
</script>

<!-- CSS -->
<style scoped>
  #erro {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    #erro {
      flex-direction: column;
    }
  }
</style>
