<!-- HTML -->
<template>
  <section class="vantagens">
    <div class="quadros">
      <div class="titulo">
        <h2>VANTAGENS QUE VOCÊ <span>SÓ ENCONTRA AQUI</span></h2>
      </div>
      <!-- Quadro -->
      <div class="quadro">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-carro.png">
        <p>Diversos <b>cursos</b> de cuidados automotivos <b>para sua evolução</b></p>
        <span></span>
      </div>
      <!-- Quadro -->
      <div class="quadro">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-foguete.png">
        <p>Plataforma <b>online para facilitar</b> seu aprendizado</p>
        <span></span>
      </div>
      <!-- Quadro -->
      <div class="quadro">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-player.png">
        <p>Aulas exclusivas com <b>grandes profissionais do mercado</b></p>
        <span></span>
      </div>
      <!-- Quadro -->
      <div class="quadro">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-livros.png">
        <p><b>Materiais complementares</b> para estudo</p>
        <span></span>
      </div>
      <!-- Quadro -->
      <div class="quadro">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-atendente.png">
        <p>Acompanhamento e <b>suporte técnico</b> especializado</p>
        <span></span>
      </div>
      <!-- Quadro -->
      <div class="quadro">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-certificado.png">
        <p><b>Certificados</b> individual por curso concluído</p>
        <span></span>
      </div>
      <!-- Quadro -->
      <div class="quadro">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-presente.png">
        <p><b>Benefícios e bônus</b> à medida que você avança</p>
        <span></span>
      </div>
      <!-- Quadro -->
      <div class="quadro">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-dois.png">
        <p><b>Acesso por dois anos</b> em qualquer curso que fizer</p>
        <span></span>
      </div>
      <!-- Quadro -->
      <div class="quadro">
        <img src="https://arquivos.wizoomplay.com/images/site/index/icone-responsivo.png">
        <p><b>Fácil acesso</b> em celular, notebook, Pc e até na Tv</p>
        <span></span>
      </div>
    </div>
  </section>
</template>

<!-- JS -->
<script>
export default {
}
</script>

<!-- CSS -->
<style scoped>
  section.vantagens {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 50px;
    width: 100%;
    background-color: var(--cor-escuro-1);
  }
  /* Quadro */
  .quadros {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  .quadros .titulo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 0 20px 0;
  }
  .quadros h2 {
    font-family: var(--bold);
    font-size: clamp(1.6rem, 3vw, 2rem);
    color: var(--cor-branco);
    line-height: 1.2;
    text-align: center;
    width: 100%;
  }
  .quadros h2 span {
    color: var(--cor-wizoomplay);
  }
  .quadros .quadro {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    background: linear-gradient(to top, rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0));
    border-radius: 10px;
    width: 33%;
  }
  .quadros .quadro img {
    width: 100%;
    max-width: 120px;
  }
  .quadros .quadro p {
    font-family: var(--bold);
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: var(--cor-branco);
    line-height: 1.5;
    padding: 0 0 0 20px;
    width: 100%;
    max-width: 300px;
  }
  .quadros .quadro p b {
    color: var(--cor-wizoomplay);
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    section.vantagens {
      padding: 50px 20px;
      width: 100%;
    }
    /* Quadro */
    .quadros h2 {
      max-width: 400px;
    }
    .quadros .quadro {
      width: 49.5%;
    }
  }
  @media screen and (max-width: 600px) {
    .quadros .quadro {
      width: 100%;
      padding: 30px 20px 20px 20px;
    }
    .quadros .quadro img {
      max-width: 80px;
    }
    .quadros h2 {
      max-width: 300px;
      font-size: clamp(1.2rem, 3vw, 1.5rem);
    }
  }
</style>
