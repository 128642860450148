export default {
  state: {
    mostrarModalCera: false,
    mostrarModalCursos: false,
    mostrarModalLoja: false,
    mostrarModalYoutube: false,
    mostrarModalPrecificacao: false,
  },
  mutations: {
    WIDNEI_MOSTRAR_MODAL_CERA(state, payload) {
      state.mostrarModalCera = payload
    },
    WIDNEI_MOSTRAR_MODAL_CURSOS(state, payload) {
      state.mostrarModalCursos = payload
    },
    WIDNEI_MOSTRAR_MODAL_LOJA(state, payload) {
      state.mostrarModalLoja = payload
    },
    WIDNEI_MOSTRAR_MODAL_YOUTUBE(state, payload) {
      state.mostrarModalYoutube = payload
    },
    WIDNEI_MOSTRAR_MODAL_PRECIFICACAO(state, payload) {
      state.mostrarModalPrecificacao = payload
    }
  },
  namespaced: true
}
