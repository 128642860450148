import { api } from './axios'

export default {
  pegarCursosParaLandingPage() {
    const method = 'GET'
    const url = 'cursos/landing-page'

    return api(method, url, null)
  }
}
